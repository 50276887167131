import { AppProps } from 'next/app'
import Head from 'next/head'
import NavBar from '../components/NavBar'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/bootstrap.overrides.css'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { appWithTranslation } from 'next-i18next'

config.autoAddCss = false

// const typography = new Typography(parnassus)
//   baseFontSize: '18px',
//   baseLineHeight: 1.45,
//   headerFontFamily: ['Avenir Next', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
//   bodyFontFamily: ['Georgia', 'serif'],
//   // See below for the full list of options.
// })

// // Output CSS as string.
// typography.toString()
//
// // Or insert styles directly into the <head> (works well for client-only
// // JS web apps.)
// typography.injectStyles()

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* <Provider session={pageProps.session}> */}
      <Head>
        <title>Zenbox</title>
        {/* <style>{typography.toString()}</style> */}
      </Head>
      <NavBar />
      {/* <SessionGuard excludedPaths={['/', '/404', '/tasks', /^\/tasks\/[^/]+$/]}> */}
      <Component {...pageProps} />
      {/* </SessionGuard> */}
      {/* <footer className={styles.footer}> */}
      {/*  &copy; Playbook, {new Date().getFullYear()} */}
      {/* </footer> */}
      {/* </Provider> */}
    </>
  )
}

export default appWithTranslation(App)
