import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { getExecutorIds } from '../src/zenbox.client'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

// function useTranslationWithNamespaces<N = Record<string, boolean>, TKPrefix>(namespacesRec: N): Record<keyof N, TFunction<keyof N, TKPrefix>> {
//   const namespaces = Object.entries(namespacesRec).filter(([key, value]) => value).map(([key, value]) => key)
//   const { t } = useTranslation(namespaces)
//   const entries = namespaces.map(n => [n, withNamespace(t, n)])
//   return Object.fromEntries(entries)
// }

export default function NavBar() {
  const executors = getExecutorIds()

  const { locale } = useRouter()
  const { t: tc } = useTranslation('common')
  // const tc = identity

  const guideUrl = `/${locale}/guides`
  const playToEarnGamesUrl = `/${locale}/play-to-earn/games`
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href={guideUrl}>Crypto Wiki</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* <Nav className="mr-auto"> */}
          {/*  {executors.map(executor => ( */}
          {/*    <Nav.Link key={executor} href={`/tasks/${executor}`}>{startCase(executor)}</Nav.Link> */}
          {/*  ))} */}
          {/* </Nav> */}
          <Nav className="mr-auto">
            <Nav.Link href={guideUrl}>{tc('navbar.guides')}</Nav.Link>
            <Nav.Link href={playToEarnGamesUrl}>{tc('navbar.playToEarnGames')}</Nav.Link>
          </Nav>
          {/* <Nav className="mr-auto"> */}
          {/*   <Nav.Link href={'/tasks'}>Executors</Nav.Link> */}
          {/* </Nav> */}
          {/* <Nav className="mr-auto"> */}
          {/*   <Nav.Link href="https://denisgorbachev.github.io/zenbox-docs/">Docs</Nav.Link> */}
          {/* </Nav> */}
          {/* <Form inline> */}
          {/*  <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}
          {/*  <Button variant="outline-success">Search</Button> */}
          {/* </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
